<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        <b>{{
          inputs.language === 'en' ? 'Select the best answer' : 'Sélectionnez la meilleure réponse'
        }}</b
        >.
        {{
          inputs.language === 'en'
            ? 'Why is it better to perform several small extractions than a single large one?'
            : "Pourquoi est-il préférable de faire plusieurs petites extractions plutôt qu'une seule avec beaucoup de solvant?"
        }}
      </p>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <v-btn-toggle
        v-model="inputs.language"
        class="ml-2"
        size="small"
        dense="dense"
        rounded="rounded"
        mandatory
      >
        <v-btn :value="'en'">English</v-btn>
        <v-btn :value="'fr'">Français</v-btn>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab3Q4',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'More of the compound can be recovered with multiple extractions with smaller amounts of solvent, since some organic molecules are also soluble in water.',
          value: 'moreCompound',
        },
        {text: 'It is less wasteful to use just one amount of solvent.', value: 'lessWaste'},
        {text: 'To remove all water from the organic phase.', value: 'removeWater'},
        {
          text: 'To avoid diluting the solutions too much due to too large a volume.',
          value: 'avoidDiluting',
        },
      ],
      optionsFr: [
        {
          text: 'Pour éviter que les solutions soient trop diluées en ajoutant trop de solvent.',
          value: 'avoidDilutingFr',
        },
        {
          text: "Une plus grande quantité du composé organique sera recouverte par plusieurs extractions avec des petites quantités de solvent, car certains composés organiques sont aussi solubles dans l'eau.",
          value: 'moreCompoundFr',
        },
        {text: "Pour retirer toute l'eau de la phase organique.", value: 'removeWaterFr'},
        {
          text: "C'est moins de gaspillage si on utilise seulement un volume de solvent.",
          value: 'lessWasteFr',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
